import { useEffect } from 'react'
import { navigate } from 'gatsby'
import { DOWNLOADER } from '../../data/constants'

const UserRedirect = () => {
  useEffect(() => {
    navigate(DOWNLOADER)
  })
  return null
}
export default UserRedirect
